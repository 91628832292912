import { createUploadLink } from 'apollo-upload-client';

import { customFetch } from '../customFetch';

const env = import.meta.env.VITE_ENV;
const apiUrl = import.meta.env.VITE_API_URL;

const uploadLink = new createUploadLink({
  uri: env === 'localhost' ? '/graphql' : apiUrl,
  fetch: customFetch,
  credentials: 'omit',
});

export { uploadLink };
