import { ReactNode, Suspense, lazy } from 'react';

import * as Sentry from '@sentry/react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { Loading } from 'components/Loading';
import { PrivateRoute } from 'components/PrivateRoute';
import { ScrollToTop } from 'components/ScrollToTop';

import languages from 'constants/languages';

import routes from './routes';

const Page404 = lazy(() => import('scenes/Page404'));

const generateRoutes = () => {
  const appRoutes = Object.entries(routes);

  let routeComponents: ReactNode[] = [];

  languages.forEach(lang => {
    for (const [key, route] of appRoutes) {
      if (route.options?.private) {
        routeComponents = [
          ...routeComponents,
          <Route
            // @ts-ignore
            path={route[lang]}
            element={
              <PrivateRoute>
                <route.element />
              </PrivateRoute>
            }
            key={key}
          />,
        ];
      } else {
        routeComponents = [
          ...routeComponents,
          <Route
            // @ts-ignore
            path={route[lang]}
            element={<route.element />}
            key={key}
          />,
        ];
      }
    }
  });

  return routeComponents;
};

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function AppRouter() {
  const routes = generateRoutes();

  return (
    <BrowserRouter>
      <ScrollToTop />

      <Suspense fallback={<Loading sx={{ height: '100vh' }} />}>
        <SentryRoutes>
          {routes}

          <Route
            path="*"
            element={<Page404 />}
          />
        </SentryRoutes>
      </Suspense>
    </BrowserRouter>
  );
}

export { AppRouter };
