import { setContext } from '@apollo/client/link/context';

const token = import.meta.env.VITE_API_AUTHENTIFICATION_TOKEN;

const contextLink = setContext((_request, { headers }) => ({
  headers: {
    ...headers,
    authorization: `Token ${token}`,
  },
}));

export { contextLink };
