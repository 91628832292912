import { Suspense, lazy, useSyncExternalStore } from 'react';

const ErrorSnackbar = lazy(() => import('./ErrorSnackbar'));
const ErrorSnackbarAuth = lazy(() => import('./ErrorSnackbarAuth'));

import { store } from './store';

function OperationErrors() {
  const state = useSyncExternalStore(store.subscribe, () =>
    store.getSnapshot()
  );

  return (
    <Suspense fallback={null}>
      {state.visibleSnackbar && <ErrorSnackbar />}
      {state.visibleSnackbarAuth && <ErrorSnackbarAuth />}
    </Suspense>
  );
}

export { OperationErrors };
