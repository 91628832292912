import { ApolloLink, Observable } from '@apollo/client';

const connections = {};

const cancelRequestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      const context = operation.getContext();

      const connectionHandle = forward(operation).subscribe({
        next: (...arg) => {
          observer.next(...arg);
        },
        error: (...arg) => {
          cleanUp();
          observer.error(...arg);
        },
        complete: (...arg) => {
          cleanUp();
          observer.complete(...arg);
        },
      });

      const requestTrackerId = context?.requestTrackerId;

      const cleanUp = () => {
        connectionHandle.unsubscribe();
        delete connections[requestTrackerId];
      };

      if (requestTrackerId) {
        const controller = new AbortController();
        controller.signal.onabort = cleanUp;

        operation.setContext({
          ...context,
          fetchOptions: {
            signal: controller.signal,
          },
        });

        if (connections[requestTrackerId]) {
          connections[requestTrackerId].abort();
        }

        connections[requestTrackerId] = controller;
      }

      return connectionHandle;
    })
);

export { cancelRequestLink };
