import { compile } from 'path-to-regexp';

import routes from 'router/routes';

class RoutesHelper {
  // Generate the route path based on language, routekey and params
  //
  // @return String
  static routePath = (language, routeKey, keys = {}, getParams = {}) => {
    const toPath = compile(this.routeRawPath(language, routeKey));

    let routePath = '#';

    try {
      routePath = toPath(keys);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      routePath = '#';
    }

    const params = new URLSearchParams({
      ...getParams,
    });

    if (params.size > 0) {
      routePath += `?${params.toString()}`;
    }

    return routePath;
  };

  // Return the raw route path based on language, routekey and params
  //
  // @return String
  static routeRawPath = (language, routeKey) => {
    let routeNode = this.getRouteNode(routeKey);

    if (!routeNode[language]) {
      throw new Error(`Language undefined for this routeKey => ${routeKey} `);
    }

    return routeNode[language];
  };

  // Return the route node from routeKey
  //
  // @return Object
  static getRouteNode(routeKey) {
    if (!routes[routeKey]) {
      throw new Error(`Undefined route from routeKey => ${routeKey} `);
    }

    return routes[routeKey];
  }

  // Get route route from raw path
  //
  // @return Object
  static getRouteFromPath(language, path) {
    let routeMatch = null;

    for (var index in routes) {
      var route = routes[index];

      if (route[language] === path) {
        routeMatch = route;
        break;
      }
    }

    return routeMatch;
  }

  // Get routeKey route from raw path
  //
  // @return Object
  static getRouteKeyFromPath(language, path) {
    let routeKeyMatch = null;

    for (var index in routes) {
      var route = routes[index];

      if (route[language] === path) {
        routeKeyMatch = index;
        break;
      }
    }

    return routeKeyMatch;
  }
}

export default RoutesHelper;
