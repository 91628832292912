import { ReactNode, Suspense, lazy } from 'react';
import { ApolloProvider } from '@apollo/client';

import { apolloClient } from 'apollo/client';

const DeprecationsOverlay = lazy(
  () => import('apollo/links/deprecations/DeprecationsOverlay')
);

interface CustomProviderProps {
  children: ReactNode;
}

function CustomProvider({ children }: CustomProviderProps) {
  return (
    <ApolloProvider client={apolloClient}>
      {children}

      {(import.meta.env.VITE_ENV === 'development' ||
        import.meta.env.VITE_ENV === 'localhost') && (
        <Suspense fallback={null}>
          <DeprecationsOverlay />
        </Suspense>
      )}
    </ApolloProvider>
  );
}

export { CustomProvider as ApolloProvider };
