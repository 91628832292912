import { SentryErrorBoundary } from './sentry/SentryErrorBoundary';

// Global css
import 'assets/styles/main.scss';

import { AppRouter } from 'router/AppRouter';

import { LoginListener } from 'auth/LoginListener';
import { LogoutListener } from 'auth/LogoutListener';

import { ApolloProvider } from 'providers/ApolloProvider';
import { LocalesProvider } from 'providers/LocalesProvider';
import { MuiThemeProvider } from 'providers/MuiThemeProvider';

import { OperationErrors } from 'operationErrors/OperationErrors';

import { NewVersion } from 'components/NewVersion';
import { PartnerPopup } from 'components/PartnerPopup';

import { AuthProvider } from 'contexts/Auth';
import { CurrencyProvider } from 'contexts/Currency';
import { SnackbarProvider } from 'contexts/Snackbar';

import 'cookieConsent';
import 'googleTagManager';

export const App = () => {
  return (
    <LocalesProvider>
      <MuiThemeProvider>
        {import.meta.env.PROD && <NewVersion />}

        <SentryErrorBoundary>
          <ApolloProvider>
            <AuthProvider>
              <OperationErrors />

              <CurrencyProvider>
                <SnackbarProvider>
                  <AppRouter />
                  <PartnerPopup />

                  <LoginListener />
                  <LogoutListener />
                </SnackbarProvider>
              </CurrencyProvider>
            </AuthProvider>
          </ApolloProvider>
        </SentryErrorBoundary>
      </MuiThemeProvider>
    </LocalesProvider>
  );
};
