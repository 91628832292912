import { Components, Theme } from '@mui/material';

const style: Components<Theme>['MuiBreadcrumbs'] = {
  defaultProps: {
    'separator': '›',
    'aria-label': 'breadcrumb',
    'className': 'breadcrumbs',
  },
};

export default style;
