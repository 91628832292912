import { gql } from '@apollo/client';

import FragmentSelf from 'graphql/fragments/FragmentSelf';

export default gql`
  query self {
    self {
      ...FragmentSelf
    }
  }

  ${FragmentSelf}
`;
