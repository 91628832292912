import { Suspense, lazy, useEffect, useState } from 'react';

const HappyPal = lazy(() => import('./HappyPal'));
const U2Guide = lazy(() => import('./U2Guide'));

type Partner = 'HappyPal' | 'U2Guide';

function PartnerPopup() {
  const [partner, setPartner] = useState<Partner | null>(null);

  useEffect(() => {
    const { hash } = window.location;

    if (['#modal=happypal', '#popup=happypal'].includes(hash)) {
      setPartner('HappyPal');
    }

    if (['#modal=u2guide', '#popup=u2guide'].includes(hash)) {
      setPartner('U2Guide');
    }
  }, []);

  if (partner === 'HappyPal') {
    return (
      <Suspense fallback={null}>
        <HappyPal onClose={() => setPartner(null)} />
      </Suspense>
    );
  }

  if (partner === 'U2Guide') {
    return (
      <Suspense fallback={null}>
        <U2Guide onClose={() => setPartner(null)} />
      </Suspense>
    );
  }

  return null;
}

export { PartnerPopup };
