import { Components, dialogActionsClasses, Theme } from '@mui/material';

const style: Components<Theme>['MuiDialogTitle'] = {
  defaultProps: {
    component: 'h6',
  },
  styleOverrides: {
    root: ({ theme }) => ({
      'backgroundColor': 'rgba(18, 18, 16, 0.04)',
      'padding': '32px 96px 16px 48px',

      '& > button': {
        'position': 'absolute',
        'top': 8,
        'right': 8,
        'color': theme.palette.grey[500],

        '&:hover': {
          color: theme.palette.grey[900],
          backgroundColor: 'unset',
        },
      },

      // case necessary for the old forms
      [`& + .${dialogActionsClasses.root}`]: {
        padding: '32px 48px',
      },
    }),
  },
};

export default style;
