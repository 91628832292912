import { ApolloLink } from '@apollo/client';

class DeprecationsLink extends ApolloLink {
  constructor() {
    super();
    this.callbacks = [];
  }

  request(operation, forward) {
    const self = this;

    return forward(operation).map(data => {
      if (typeof data.extensions?.deprecations === 'undefined') {
        return data;
      }

      self.notify(data.extensions.deprecations);
      return data;
    });
  }

  register(callback) {
    this.callbacks.push(callback);
  }

  notify(deprecations) {
    this.callbacks.forEach(callback => callback(deprecations));
  }
}

export { DeprecationsLink };
