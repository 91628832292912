import { Components, Theme } from '@mui/material';

const style: Components<Theme>['MuiAlert'] = {
  styleOverrides: {
    root: {
      '&.white-on-beige': {
        backgroundColor: '#fff',
        border: '2px solid #e53f3f',
      },
    },
  },
};

export default style;
