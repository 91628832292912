import { gql } from '@apollo/client';

export default gql`
  fragment FragmentSelf on Self {
    location {
      city
      country {
        code
        name
      }
      lat
      lng
      stateCode
      street
      streetNumber
      zip
    }
    avatar {
      url64
      url320
    }
    birthday
    coverPhoto {
      id
      url1280
    }
    description
    doneCountries {
      id
      name
    }
    email
    emailConfirmed
    facebook
    firstname
    foodEaten
    fullname
    id
    ifAnimal
    ifPerson
    ifPlant
    ifTool
    instagram
    intensityLvl
    isExplorer
    isHost
    isTraveler
    isVaolo
    language {
      id
      name
    }
    lastname
    meta {
      slug
    }
    pendingRoleRequests
    phoneFixedline
    phoneFixedlineCountryCode
    phoneMobile
    phoneMobileCountryCode
    promoCode
    quote
    riskLvl
    roles
    todoCountries {
      id
      name
    }
    trendinessLvl
    trusted
    video
  }
`;
