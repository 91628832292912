import { BatchHttpLink } from '@apollo/client/link/batch-http';

import { customFetch } from '../customFetch';

const env = import.meta.env.VITE_ENV;
const apiUrl = import.meta.env.VITE_API_URL;

const batchLink = new BatchHttpLink({
  uri: env === 'localhost' ? '/graphql' : apiUrl,
  fetch: customFetch,
  credentials: 'omit',
  batchMax: 5,
  batchInterval: 20,
  includeUnusedVariables: true,
});

export { batchLink };
