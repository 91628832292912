import { Components, Theme } from '@mui/material';

const style: Components<Theme>['MuiAutocomplete'] = {
  defaultProps: {
    componentsProps: {
      paper: {
        elevation: 1,
      },
    },
  },
};

export default style;
