import { ReactNode, useEffect, useState } from 'react';

import { I18nextProvider } from 'react-i18next';

import i18n from '../i18n';

interface LocalesProviderProps {
  children: ReactNode;
}

function LocalesProvider({ children }: LocalesProviderProps) {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    const load = async () => {
      const translations = (
        await import(`../locales/${i18n.language}/index.ts`)
      ).default;

      for (const key in translations) {
        i18n.addResourceBundle(i18n.language, key, translations[key]);
      }

      setReady(true);
    };

    load();
  }, []);

  return (
    <I18nextProvider i18n={i18n}>{ready ? children : null}</I18nextProvider>
  );
}

export { LocalesProvider };
