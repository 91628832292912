import createCache from '@emotion/cache';

import { isPrerender } from 'helpers/isPrerender';

export default function createEmotionCache() {
  return createCache({
    key: 'css',
    speedy: isPrerender() ? false : true,
    prepend: true,
  });
}
