import { createTheme, responsiveFontSizes } from '@mui/material/styles';

import type {} from '@mui/x-date-pickers/themeAugmentation';

import alert from './alert';
import autocomplete from './autocomplete';
import breadcrumbs from './breadcrumbs';
import button from './button';
import buttonBase from './buttonBase';
import desktopTimePicker from './desktopTimePicker';
import dialog from './dialog';
import dialogActions from './dialogActions';
import dialogContent from './dialogContent';
import dialogTitle from './dialogTitle';
import formControlLabel from './formControlLabel';
import paper from './paper';
import slider from './slider';
import tab from './tab';
import useMediaQuery from './useMediaQuery';

const fontMulish = ['Mulish', 'sans-serif'].join(',');
const fontPoppins = ['Poppins', 'sans-serif'].join(',');

let theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1272, // custom value
      xl: 1536,
    },
  },
  components: {
    MuiAlert: alert,
    MuiAutocomplete: autocomplete,
    MuiBreadcrumbs: breadcrumbs,
    MuiButton: button,
    MuiButtonBase: buttonBase,
    MuiDesktopTimePicker: desktopTimePicker,
    MuiDialog: dialog,
    MuiDialogActions: dialogActions,
    MuiDialogContent: dialogContent,
    MuiDialogTitle: dialogTitle,
    MuiFormControlLabel: formControlLabel,
    MuiPaper: paper,
    MuiSlider: slider,
    MuiTab: tab,
    MuiUseMediaQuery: useMediaQuery,
  },
  palette: {
    primary: {
      main: '#00B181',
      contrastText: '#fff',
    },
    secondary: {
      main: '#E14900',
    },
    error: {
      main: '#D32F2F',
    },
    warning: {
      main: '#ED6C02',
    },
    info: {
      main: '#0288D1',
    },
    success: {
      main: '#2E7D32',
    },
    background: {
      default: '#F0ECE4',
    },
    text: {
      primary: 'rgba(18, 18, 16, 0.87)',
      secondary: 'rgba(18, 18, 16, 0.60)',
      disabled: 'rgba(18, 18, 16, 0.38)',
    },
  },
  shape: {
    borderRadius: 8,
  },
});

theme = createTheme(theme, {
  palette: {
    monochrome: theme.palette.augmentColor({
      color: {
        main: '#121210',
      },
      name: 'monochrome',
    }),
  },
});

theme = createTheme(theme, {
  typography: {
    fontFamily: fontMulish,
    h1: {
      fontFamily: fontPoppins,
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(36),
      lineHeight: 44 / 36,
      letterSpacing: '-0.5px',
    },
    h2: {
      fontFamily: fontPoppins,
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(32),
      lineHeight: 40 / 32,
      letterSpacing: '-0.5px',
    },
    h3: {
      fontFamily: fontPoppins,
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(28),
      lineHeight: 36 / 28,
      letterSpacing: '-0.5px',
    },
    h4: {
      fontFamily: fontPoppins,
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(24),
      lineHeight: 32 / 24,
      letterSpacing: 'normal',
    },
    h5: {
      fontFamily: fontPoppins,
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(20),
      lineHeight: 26 / 20,
      letterSpacing: 'normal',
    },
    h6: {
      fontFamily: fontPoppins,
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(18),
      lineHeight: 24 / 18,
      letterSpacing: 'normal',
    },
    subtitle1: {
      fontFamily: fontMulish,
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(20),
      lineHeight: 28 / 20,
      letterSpacing: 'normal',
    },
    subtitle2: {
      fontFamily: fontMulish,
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 20 / 14,
      letterSpacing: '0.2px',
    },
    body1: {
      fontFamily: fontMulish,
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 24 / 16,
      letterSpacing: 'normal',
    },
    body2: {
      fontFamily: fontMulish,
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 20 / 14,
      letterSpacing: '0.2px',
    },
    button: {
      fontFamily: fontMulish,
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 20 / 16,
      letterSpacing: 'normal',
      textTransform: 'none',
    },
    caption: {
      fontFamily: fontMulish,
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: 16 / 12,
      letterSpacing: '0.2px',
    },
    overline: {
      fontFamily: fontMulish,
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(10),
      lineHeight: 24 / 10,
      letterSpacing: '1px',
    },
  },
});

theme = responsiveFontSizes(theme, { breakpoints: ['sm'], factor: 10 });

export default theme;
