import {
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useQuery } from '@apollo/client';

import { useTranslation } from 'react-i18next';

import { actionsOnLogin } from 'auth/actionsOnLogin';
import { actionsOnLogout } from 'auth/actionsOnLogout';

import { removeUserToken } from 'auth/removeUserToken';
import { setUserToken } from 'auth/setUserToken';

import { Self } from 'generated/graphql';

import SELF from 'graphql/queries/self';

import { loginPostMessage } from 'auth/loginChannel';
import { logoutPostMessage } from 'auth/logoutChannel';

interface ContextProps {
  isAuthenticated: boolean;
  login: (token: string, rememberMe: boolean, user: Self) => Promise<void>;
  loginForTabsSync: (user: Self) => Promise<void>;
  logout: () => void;
  logoutForTabsSync: () => void;
  userRefresh: () => void;
  self: Self | null;
}

const AuthContext = createContext<ContextProps>({} as ContextProps);

interface ProviderProps {
  children: ReactNode;
}

function AuthProvider({ children }: ProviderProps) {
  const { i18n } = useTranslation();

  const [ready, setReady] = useState(false);

  const firstCallToSelf = useRef(true);

  const [IsUserConnected, setIsUserConnected] = useState(false);

  const { data, refetch } = useQuery<{ self: Self }>(SELF, {
    context: {
      firstCallToSelf,
    },
    variables: {
      locale: i18n.language,
    },
    onCompleted: ({ self }) => {
      firstCallToSelf.current = false;

      if (self) {
        setIsUserConnected(true);
        actionsOnLogin(self);
        setReady(true);
      }
    },
    onError: () => {
      firstCallToSelf.current = false;

      removeUserToken();

      setIsUserConnected(false);
      actionsOnLogout();
      setReady(true);
    },
  });

  /**
   * This function should be used when it is not possible to update the user with a mutation response.
   */
  const userRefresh = () => {
    refetch();
  };

  const login = async (token: string, rememberMe: boolean, user: Self) => {
    setUserToken(token, rememberMe);

    setIsUserConnected(true);
    actionsOnLogin(user);

    await refetch();

    loginPostMessage(user);
  };

  const loginForTabsSync = async (user: Self) => {
    setIsUserConnected(true);

    if (user) {
      actionsOnLogin(user);
    }

    await refetch();
  };

  const logout = () => {
    removeUserToken();

    setIsUserConnected(false);
    actionsOnLogout();

    logoutPostMessage();
  };

  const logoutForTabsSync = () => {
    setIsUserConnected(false);
    actionsOnLogout();
  };

  const currentUser = data && IsUserConnected;

  const memoedValue = useMemo(
    () => ({
      isAuthenticated: currentUser ? Boolean(data.self) : false,
      login,
      loginForTabsSync,
      logout,
      logoutForTabsSync,
      userRefresh,
      self: currentUser ? data.self : null,
    }),
    [data, IsUserConnected]
  );

  return (
    <AuthContext.Provider value={memoedValue}>
      {ready && children}
    </AuthContext.Provider>
  );
}

const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('A Provider is missing to use the useAuth hook');
  }

  return context;
};

export { AuthContext, AuthProvider, useAuth };
