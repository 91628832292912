import { captureException, captureMessage } from '@sentry/react';

import { onError } from '@apollo/client/link/error';

import { store } from 'operationErrors/store';

import i18next from 'i18n';

enum Code {
  Unauthorized = 'unauthorized',
  Forbidden = 'forbidden',
  NotFound = 'not_found',
  UnprocessableEntity = 'unprocessable_entity',
  InternalServerError = 'internal_server_error',
}

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    for (const error of graphQLErrors) {
      const { extensions } = error;

      const entryPoint = operation.getContext().entryPoint;
      const firstCallToSelf = operation.getContext().firstCallToSelf;

      if (entryPoint || firstCallToSelf) {
        break;
      }

      const code = extensions?.code;

      switch (code) {
        case Code.Unauthorized:
          store.dispatch('showSnackbarAuth');
          break;

        case Code.Forbidden:
          store.dispatch('showSnackbar', {
            title: i18next.t('onError:forbidden.title'),
            message: i18next.t('onError:forbidden.message'),
            eventId: '',
          });
          break;

        case Code.NotFound:
          store.dispatch('showSnackbar', {
            title: i18next.t('onError:not_found.title'),
            message: i18next.t('onError:not_found.message'),
            eventId: '',
          });
          break;

        case Code.UnprocessableEntity:
          store.dispatch('showSnackbar', {
            title: i18next.t('onError:unprocessable_entity.title'),
            message: i18next.t('onError:unprocessable_entity.message'),
            eventId: captureMessage(error.message),
          });
          break;

        case Code.InternalServerError:
          store.dispatch('showSnackbar', {
            title: i18next.t('onError:internal_server_error.title'),
            message: i18next.t('onError:internal_server_error.message'),
            eventId: captureMessage(error.message),
          });
          break;

        default:
          store.dispatch('showSnackbar', {
            title: i18next.t('onError:unknow.title'),
            message: i18next.t('onError:unknow.message'),
            eventId: captureMessage(error.message),
          });
      }
    }
  }

  if (networkError) {
    store.dispatch('showSnackbar', {
      title: i18next.t('onError:network.title'),
      message: i18next.t('onError:network.message'),
      eventId: captureMessage(networkError.message),
    });

    captureException(networkError);
  }
});

export { errorLink };
