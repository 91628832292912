import { Components, Theme } from '@mui/material';

const style: Components<Theme>['MuiFormControlLabel'] = {
  styleOverrides: {
    root: {
      /**
       * https://github.com/mui/material-ui/blob/master/packages/mui-material/src/FormControlLabel/FormControlLabel.js
       *
       * As we do not have row presentations, we can put 0 to avoid a useless margin.
       */
      marginRight: 0,
    },
  },
};

export default style;
