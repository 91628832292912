import { Components, Theme } from '@mui/material';

const style: Components<Theme>['MuiDialogActions'] = {
  styleOverrides: {
    spacing: {
      '& > :not(:first-of-type)': {
        marginLeft: 16,
      },

      // case necessary for the old forms
      '& > a + button': {
        marginLeft: 16,
      },
    },
  },
};

export default style;
