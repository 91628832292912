import { StrictMode } from 'react';

import { createRoot } from 'react-dom/client';

import './fonts';
import './configDayjs';
import './polyfills';
import './Sentry';

import { App } from './App';

const container = document.getElementById('root') as HTMLElement;

const root = createRoot(container);

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
