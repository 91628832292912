import { Components, Theme } from '@mui/material';

const style: Components<Theme>['MuiSlider'] = {
  styleOverrides: {
    root: {
      '&.legacy': {
        color: '#000',
      },
    },
    rail: {
      '.MuiSlider-root.legacy &': {
        opacity: 1,
      },
    },
    thumb: {
      '.MuiSlider-root.legacy &': {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '1px solid #00b483',
      },
    },

    track: {
      border: 'unset',
    },
  },
};

export default style;
