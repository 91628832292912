import { useTranslation } from 'react-i18next';

import { Box, Typography } from '@mui/material';
import BugReportIcon from '@mui/icons-material/BugReport';

interface SentryFallbackProps {
  eventId: string | null;
}

function SentryFallback({ eventId }: SentryFallbackProps) {
  const { t } = useTranslation('general');

  return (
    <Box
      sx={{
        marginTop: { xs: 20, sm: 24 },
        padding: 2,
        textAlign: 'center',
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'inline-flex',
          gap: 1,
        }}
      >
        <BugReportIcon fontSize="large" />

        <Typography
          color="error"
          component="h1"
          variant="h3"
        >
          {t('fallback.title')}
        </Typography>
      </Box>

      <Box sx={{ mt: 2 }}>{t('fallback.content')}</Box>

      <Box sx={{ mt: 2 }}>Event ID: {eventId}</Box>
    </Box>
  );
}

export { SentryFallback };
