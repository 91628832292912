import dayjs from 'dayjs';

function getExpires() {
  const today = dayjs();
  const inOneMonth = today.add(1, 'month');

  return inOneMonth.startOf('hour').toDate();
}

export { getExpires };
