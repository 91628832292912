import { createContext, ReactNode, useContext, useState } from 'react';

import { Alert, Snackbar } from '@mui/material';

import type { AlertProps } from '@mui/material';

type Options = {
  autoHideDuration: number | null;
  severity: AlertProps['severity'];
  variant: AlertProps['variant'];
  message: string;
};

type ShowSnackbar = (options: Options) => void;

const SnackbarContext = createContext<ShowSnackbar>(undefined!);

interface SnackbarProviderProps {
  children: ReactNode;
}

function SnackbarProvider({ children }: SnackbarProviderProps) {
  const [open, setOpen] = useState(false);

  const [autoHideDuration, setAutoHideDuration] = useState<number | null>(null);
  const [severity, setSeverity] = useState<AlertProps['severity']>();
  const [variant, setVariant] = useState<AlertProps['variant']>();
  const [message, setMessage] = useState('');

  const handleClose = () => {
    setAutoHideDuration(null);
    setSeverity(undefined);
    setVariant(undefined);
    setMessage('');

    setOpen(false);
  };

  const showSnackbar: ShowSnackbar = options => {
    const { autoHideDuration, severity, variant, message } = options;

    setAutoHideDuration(autoHideDuration);
    setSeverity(severity);
    setVariant(variant);
    setMessage(message);

    setOpen(true);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
        open={open}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          variant={variant}
        >
          {message}
        </Alert>
      </Snackbar>

      <SnackbarContext.Provider value={showSnackbar}>
        {children}
      </SnackbarContext.Provider>
    </>
  );
}

const useSnackbar = () => useContext(SnackbarContext);

export { SnackbarProvider, useSnackbar };
