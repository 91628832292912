const logoutChannel = new BroadcastChannel('logout');

export const logoutPostMessage = () => {
  logoutChannel.postMessage({
    name: 'logout',
    payload: null,
  });
};

export const logoutOnMessage = (callback: Function) => {
  callback();
};

export const logoutAllTabsListener = (callback: Function) => {
  logoutChannel.onmessage = () => {
    logoutOnMessage(callback);
  };
};
