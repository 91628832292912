import { ApolloLink } from '@apollo/client';

import { getUserToken } from 'auth/getUserToken';

const userTokenLink = new ApolloLink((operation, forward) => {
  const userToken = getUserToken();

  const hasHostToken = operation.variables.hasOwnProperty('hostToken');

  operation.variables = {
    token: hasHostToken ? undefined : userToken,
    ...operation.variables,
  };

  return forward(operation);
});

export { userTokenLink };
