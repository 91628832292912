import { setUser } from '@sentry/react';

import TagManager from 'react-gtm-module';

import { Self } from 'generated/graphql';

function actionsOnLogin(user: Self) {
  // GTM
  TagManager.dataLayer({
    dataLayer: {
      userId: user.id,
    },
  });

  // Sentry
  setUser({
    id: user.id,
  });
}

export { actionsOnLogin };
