import { Self } from 'generated/graphql';

const loginChannel = new BroadcastChannel('login');

export const loginPostMessage = (user: Self) => {
  loginChannel.postMessage({
    name: 'login',
    payload: {
      user,
    },
  });
};

export const loginOnMessage = (callback: Function, user: Self) => {
  callback(user);
};

export const loginAllTabsListener = (callback: Function) => {
  loginChannel.onmessage = event => {
    loginOnMessage(callback, event.data?.payload.user);
  };
};
