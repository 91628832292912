import {
  Components,
  dialogActionsClasses,
  dialogTitleClasses,
  Theme,
} from '@mui/material';

const style: Components<Theme>['MuiDialogContent'] = {
  styleOverrides: {
    root: ({ ownerState }) => ({
      padding: '32px 48px',

      ...(ownerState.dividers
        ? {
            [`& + .${dialogActionsClasses.root}`]: {
              padding: '24px 48px',
            },
          }
        : {
            [`.${dialogTitleClasses.root} + &`]: {
              paddingTop: 32,
            },

            [`& + .${dialogActionsClasses.root}`]: {
              padding: '12px 48px 24px',
            },
          }),
    }),
  },
};

export default style;
