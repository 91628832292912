import { ReactNode } from 'react';

import { ErrorBoundary } from '@sentry/react';

import { SentryFallback } from './SentryFallback';

interface SentryErrorBoundaryProps {
  children: ReactNode;
}

function SentryErrorBoundary({ children }: SentryErrorBoundaryProps) {
  return (
    <ErrorBoundary
      fallback={({ eventId }) => <SentryFallback eventId={eventId} />}
    >
      {children}
    </ErrorBoundary>
  );
}

export { SentryErrorBoundary };
