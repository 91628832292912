import { useEffect } from 'react';

import { useAuth } from 'contexts/Auth';

import { loginAllTabsListener } from 'auth/loginChannel';

function LoginListener() {
  const { loginForTabsSync } = useAuth();

  useEffect(() => {
    loginAllTabsListener(loginForTabsSync);
  }, []);

  return null;
}

export { LoginListener };
