import { Components, tabClasses, Theme } from '@mui/material';

const style: Components<Theme>['MuiTab'] = {
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      'flex': '0 0 auto',
      'minWidth': 0,
      'paddingLeft': 0,
      'paddingRight': 0,

      ...(ownerState.textColor === 'primary' && {
        [`&.${tabClasses.selected}`]: {
          color: theme.palette.text.primary,
        },
      }),

      '& + button': {
        marginLeft: 32,
      },
    }),
  },
};

export default style;
