type GetLanguageFunction = () => string;

const getLanguage: GetLanguageFunction = () => {
  const { pathname } = window.location;

  const found = pathname.match(/^\/([a-z]{2})\//);

  const language = found?.[1];

  if (language === 'en' || language === 'es') {
    return language;
  }

  return 'fr';
};

export { getLanguage };
