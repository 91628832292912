import { useEffect } from 'react';

import { useAuth } from 'contexts/Auth';

import { logoutAllTabsListener } from 'auth/logoutChannel';

function LogoutListener() {
  const { logoutForTabsSync } = useAuth();

  useEffect(() => {
    logoutAllTabsListener(logoutForTabsSync);
  }, []);

  return null;
}

export { LogoutListener };
