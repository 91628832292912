import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';

import { getLanguage } from 'helpers/getLanguage';

const options: InitOptions = {
  cleanCode: true,
  debug: import.meta.env.DEV,
  fallbackLng: false,
  interpolation: {
    escapeValue: false, // not needed for React!
  },
  ns: [],
  defaultNS: false,
  lng: getLanguage(),
  load: 'languageOnly',
  resources: {},
};

i18n.use(initReactI18next).init(options);

export default i18n;
