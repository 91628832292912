export const denyUrls = [
  //
  // https://docs.sentry.io/platforms/javascript/configuration/filtering/
  //

  // Facebook flakiness
  /graph\.facebook\.com/i,

  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,

  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  /^chrome-extension:\/\//i,
];
