import { useEffect, useRef, useState } from 'react';

import { Trans } from 'react-i18next';

import { useInterval, useLocalStorage } from 'usehooks-ts';

import { Alert, Link, Snackbar, Typography } from '@mui/material';

type Version = string | null;

function NewVersion() {
  const [versionChanged, setVersionChanged] = useState(false);

  const [version, setVersion] = useLocalStorage<Version>('version', null);

  const newVersionRef = useRef<string>('');

  const saveVersion = () => {
    fetch(`/version.json?now=${Date.now()}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(result => {
        setVersion(result.version);
      })
      .catch(() => {});
  };

  /**
   * Save the version when the site is loaded.
   */
  useEffect(() => {
    saveVersion();
  }, []);

  const lookForNewVersion = () => {
    fetch(`/version.json?now=${Date.now()}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => response.json())
      .then(result => {
        // First use or site data cleaned
        if (version === null) {
          setVersion(result.version);
          return;
        }

        if (version !== result.version) {
          newVersionRef.current = result.version;

          setVersionChanged(true);
        }
      })
      .catch(() => {});
  };

  // 10 minutes
  useInterval(lookForNewVersion, 60 * 10 * 1000);

  const handleRefresh = () => {
    setVersion(newVersionRef.current);

    window.location.reload();
  };

  const handleClose = () => {
    setVersionChanged(false);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={versionChanged}
    >
      <Alert
        color="monochrome"
        elevation={6}
        onClose={handleClose}
        severity="info"
        variant="filled"
      >
        <Typography variant="body2">
          <Trans
            i18nKey="components:newVersion.message"
            components={[
              <Link
                component="button"
                onClick={handleRefresh}
                sx={{ fontWeight: 700, verticalAlign: 'unset' }}
                variant="body2"
              />,
            ]}
          />
        </Typography>
      </Alert>
    </Snackbar>
  );
}

export { NewVersion };
