import { LazyExoticComponent, lazy } from 'react';

// General
const Login = lazy(() => import('../scenes/login/Login.tsx'));
const FAQ = lazy(() => import('../scenes/faq/FAQ.jsx'));
const Home = lazy(() => import('../scenes/home/Home.tsx'));
const Page = lazy(() => import('../scenes/page/Page.tsx'));
const PageId = lazy(() => import('../scenes/pageId/PageId.tsx'));

// Main pages
const Accommodation = lazy(
  () => import('../scenes/accommodation/Accommodation.tsx')
);
const AccommodationCheckout = lazy(
  () => import('../scenes/accommodationCheckout/AccommodationCheckout.tsx')
);
const Experience = lazy(() => import('../scenes/experience/Experience.tsx'));
const ExperienceCheckout = lazy(
  () => import('../scenes/experienceCheckout/ExperienceCheckout.tsx')
);
const Monty = lazy(() => import('../scenes/monty/Monty.tsx'));
const MontyCheckout = lazy(
  () => import('../scenes/montyCheckout/MontyCheckout.tsx')
);

// Academy
const Academies = lazy(() => import('../scenes/academies/Academies.jsx'));
const Academy2021 = lazy(() => import('../scenes/academy2021/Academy2021.jsx'));
const Summit = lazy(() => import('../scenes/summit/Summit.jsx'));
const ExplorerManifesto = lazy(
  () => import('../scenes/explorerManifesto/ExplorerManifesto.tsx')
);

// Documentary
const Documentary = lazy(() => import('../scenes/documentary/Documentary.tsx'));

// Account
const AccountBookings = lazy(
  () => import('../scenes/accountBookings/AccountBookings.tsx')
);
const AccountExplorations = lazy(
  () => import('../scenes/accountExplorations/AccountExplorations.tsx')
);
const AccountProfile = lazy(
  () => import('../scenes/accountProfile/AccountProfile.jsx')
);
const AccountExplorationMap = lazy(
  () => import('../scenes/accountExplorationMap/AccountExplorationMap.tsx')
);

// HOST MENU
const HostAccommodations = lazy(
  () => import('../scenes/hostAccommodations/HostAccommodations.tsx')
);
const HostBusinesses = lazy(
  () => import('../scenes/hostBusinesses/HostBusinesses.tsx')
);
const HostBookings = lazy(
  () => import('../scenes/hostBookings/HostBookings.tsx')
);

// Calendar
const HostCalendar = lazy(
  () => import('../scenes/hostCalendar/HostCalendar.tsx')
);

// CREATE
const ExplorerAccommodationCreate = lazy(
  () =>
    import(
      '../scenes/explorerAccommodationCreate/ExplorerAccommodationCreate.tsx'
    )
);
const HostAccommodationCreate = lazy(
  () => import('../scenes/hostAccommodationCreate/HostAccommodationCreate.tsx')
);
const HostBusinessCreate = lazy(
  () => import('../scenes/hostBusinessCreate/HostBusinessCreate.tsx')
);
const HostVariantCreate = lazy(
  () => import('../scenes/hostVariantCreate/HostVariantCreate.tsx')
);

// EDIT
const ExplorerAccommodationEdit = lazy(
  () =>
    import('../scenes/explorerAccommodationEdit/ExplorerAccommodationEdit.tsx')
);
const HostAccommodationEdit = lazy(
  () => import('../scenes/hostAccommodationEdit/HostAccommodationEdit.tsx')
);
const HostBusinessEdit = lazy(
  () => import('../scenes/hostBusinessEdit/HostBusinessEdit.tsx')
);
const HostVariantEdit = lazy(
  () => import('../scenes/hostVariantEdit/HostVariantEdit.tsx')
);
const HostVariantConvert = lazy(
  () => import('../scenes/hostVariantConvert/HostVariantConvert.tsx')
);

// Explorer
const Explorer = lazy(() => import('../scenes/explorer/Explorer.tsx'));
const SearchExplorers = lazy(
  () => import('../scenes/searchExplorers/SearchExplorers.tsx')
);

// Blog
const Blog = lazy(() => import('../scenes/blog/Blog.tsx'));
const BlogAuthor = lazy(() => import('../scenes/blogAuthor/BlogAuthor.tsx'));
const BlogTag = lazy(() => import('../scenes/blogTag/BlogTag.tsx'));
const BlogArticle = lazy(() => import('../scenes/blogArticle/BlogArticle.tsx'));

// Booking
const BookingAccept = lazy(
  () => import('../scenes/bookingAccept/BookingAccept.tsx')
);
const BookingRefuse = lazy(
  () => import('../scenes/bookingRefuse/BookingRefuse.tsx')
);
const PaymentConfirm = lazy(
  () => import('../scenes/paymentConfirm/PaymentConfirm.tsx')
);

// Contact
const ContactUs = lazy(() => import('../scenes/contactUs/ContactUs.tsx'));

// Conversation
const ConversationBookingHostNew = lazy(
  () =>
    import(
      '../scenes/conversationBookingHostNew/ConversationBookingHostNew.tsx'
    )
);
const ConversationBookingTravelerNew = lazy(
  () =>
    import(
      '../scenes/conversationBookingTravelerNew/ConversationBookingTravelerNew.tsx'
    )
);
const ConversationFlow = lazy(
  () => import('../scenes/conversationFlow/ConversationFlow.tsx')
);
const Conversations = lazy(
  () => import('../scenes/conversations/Conversations.tsx')
);

// MasterClass
const MasterClass = lazy(() => import('../scenes/masterclass/MasterClass.jsx'));
const AccountMasterClass = lazy(
  () => import('../scenes/accountMasterClass/AccountMasterClass.jsx')
);

const PasswordReset = lazy(
  () => import('../scenes/passwordReset/PasswordReset.jsx')
);

// Proposals
const NewExperience = lazy(
  () => import('../scenes/newExperience/NewExperience.tsx')
);

// Preview
const PreviewAccommodation = lazy(
  () => import('../scenes/preview/PreviewAccommodation.tsx')
);
const PreviewBlogArticle = lazy(
  () => import('../scenes/preview/PreviewBlogArticle.tsx')
);
const PreviewExperience = lazy(
  () => import('../scenes/preview/PreviewExperience.tsx')
);
const PreviewMonty = lazy(() => import('../scenes/preview/PreviewMonty.tsx'));
const PreviewPage = lazy(() => import('../scenes/preview/PreviewPage.tsx'));
const PreviewMasterclass = lazy(
  () => import('../scenes/preview/PreviewMasterclass.tsx')
);
const PreviewOrganisation = lazy(
  () => import('../scenes/preview/PreviewOrganisation.tsx')
);

// Validation
const ValidateEmail = lazy(
  () => import('../scenes/validateEmail/ValidateEmail.tsx')
);

// Exploration
const Exploration = lazy(() => import('../scenes/exploration/Exploration.tsx'));

// Team
const Team = lazy(() => import('../scenes/team/Team.jsx'));

// Test
const Test = lazy(() => import('../scenes/test/Test.tsx'));
const Mui = lazy(() => import('../scenes/mui/Mui.tsx'));

// Partners & Associations
const Organisations = lazy(
  () => import('../scenes/organisations/Organisations.tsx')
);
const Organisation = lazy(
  () => import('../scenes/organisation/Organisation.tsx')
);

// Carriers
const Careers = lazy(() => import('../scenes/careers/Careers.jsx'));

// Search UI
const Search = lazy(() => import('../scenes/search/Search.tsx'));

// Redirects
const RedirectionDocumentary = lazy(
  () => import('../scenes/redirectionDocumentary/RedirectionDocumentary.tsx')
);

const Countries = lazy(() => import('../scenes/countries/Countries.tsx'));
const Country = lazy(() => import('../scenes/country/Country.tsx'));
const HostChart = lazy(() => import('../scenes/hostChart/HostChart.tsx'));

interface Routes {
  [key: string]: {
    fr: string;
    en: string;
    es: string;
    options: {
      private?: boolean;
      shouldIndex: boolean;
    };
    element: LazyExoticComponent<() => JSX.Element | null>;
  };
}

const routes: Routes = {
  login: {
    fr: '/login/',
    en: '/en/login/',
    es: '/es/login/',
    options: {
      shouldIndex: false,
    },
    element: Login,
  },
  team: {
    fr: '/team/',
    en: '/en/team/',
    es: '/es/team/',
    options: {
      shouldIndex: true,
    },
    element: Team,
  },
  test: {
    fr: '/test/',
    en: '/en/test/',
    es: '/es/test/',
    options: {
      shouldIndex: false,
    },
    element: Test,
  },
  mui: {
    fr: '/mui/',
    en: '/en/mui/',
    es: '/es/mui/',
    options: {
      shouldIndex: false,
    },
    element: Mui,
  },
  home: {
    fr: '/',
    en: '/en/',
    es: '/es/',
    options: {
      shouldIndex: true,
    },
    element: Home,
  },
  contact_us: {
    fr: '/contact/',
    en: '/en/contact/',
    es: '/es/contact/',
    options: {
      shouldIndex: true,
    },
    element: ContactUs,
  },
  faq: {
    fr: '/faq/',
    en: '/en/faq/',
    es: '/es/faq/',
    options: {
      shouldIndex: true,
    },
    element: FAQ,
  },
  blog: {
    fr: '/blog/',
    en: '/en/blog/',
    es: '/es/blog/',
    options: {
      shouldIndex: true,
    },
    element: Blog,
  },
  blog_author: {
    fr: '/blog/author/:slug/',
    en: '/en/blog/author/:slug/',
    es: '/es/blog/author/:slug/',
    options: {
      shouldIndex: true,
    },
    element: BlogAuthor,
  },
  blog_tag: {
    fr: '/blog/tag/:slug/',
    en: '/en/blog/tag/:slug/',
    es: '/es/blog/tag/:slug/',
    options: {
      shouldIndex: true,
    },
    element: BlogTag,
  },
  blog_article: {
    fr: '/blog/:slug/',
    en: '/en/blog/:slug/',
    es: '/es/blog/:slug/',
    options: {
      shouldIndex: true,
    },
    element: BlogArticle,
  },
  countries: {
    fr: '/countries/',
    en: '/en/countries/',
    es: '/es/countries/',
    options: {
      shouldIndex: true,
    },
    element: Countries,
  },
  country: {
    fr: '/countries/:slug/',
    en: '/en/countries/:slug/',
    es: '/es/countries/:slug/',
    options: {
      shouldIndex: true,
    },
    element: Country,
  },
  documentary: {
    fr: '/media/resonances/',
    en: '/en/media/resonances/',
    es: '/es/media/resonances/',
    options: {
      shouldIndex: true,
    },
    element: Documentary,
  },
  documentary_redirect_1: {
    fr: '/media/résonances/',
    en: '/en/media/résonances/',
    es: '/es/media/résonances/',
    options: {
      shouldIndex: true,
    },
    element: RedirectionDocumentary,
  },
  documentary_redirect_2: {
    fr: '/resonances/',
    en: '/en/resonances/',
    es: '/es/resonances/',
    options: {
      shouldIndex: true,
    },
    element: RedirectionDocumentary,
  },
  documentary_redirect_3: {
    fr: '/résonances/',
    en: '/en/résonances/',
    es: '/es/résonances/',
    options: {
      shouldIndex: true,
    },
    element: RedirectionDocumentary,
  },
  explorer: {
    fr: '/explorers/:explorerSlug/',
    en: '/en/explorers/:explorerSlug/',
    es: '/es/explorers/:explorerSlug/',
    options: {
      shouldIndex: true,
    },
    element: Explorer,
  },
  search_explorers: {
    fr: '/search/explorers/',
    en: '/en/search/explorers/',
    es: '/es/search/explorers/',
    options: {
      shouldIndex: true,
    },
    element: SearchExplorers,
  },
  monty: {
    fr: '/experiences/:slug-monty/',
    en: '/en/experiences/:slug-monty/',
    es: '/es/experiences/:slug-monty/',
    options: {
      shouldIndex: true,
    },
    element: Monty,
  },
  monty_checkout: {
    fr: '/experiences/checkout/:slug-monty/:variantId/',
    en: '/en/experiences/checkout/:slug-monty/:variantId/',
    es: '/es/experiences/checkout/:slug-monty/:variantId/',
    options: {
      shouldIndex: false,
    },
    element: MontyCheckout,
  },
  experience: {
    fr: '/experiences/:slug/',
    en: '/en/experiences/:slug/',
    es: '/es/experiences/:slug/',
    options: {
      shouldIndex: true,
    },
    element: Experience,
  },
  experience_checkout: {
    fr: '/experiences/checkout/:slug/:variantId/',
    en: '/en/experiences/checkout/:slug/:variantId/',
    es: '/es/experiences/checkout/:slug/:variantId/',
    options: {
      shouldIndex: false,
    },
    element: ExperienceCheckout,
  },
  exploration: {
    fr: '/explorations/:explorationSlug/',
    en: '/en/explorations/:explorationSlug/',
    es: '/es/explorations/:explorationSlug/',
    options: {
      shouldIndex: true,
    },
    element: Exploration,
  },
  lodge: {
    fr: '/lodges/:slug/',
    en: '/en/lodges/:slug/',
    es: '/es/lodges/:slug/',
    options: {
      shouldIndex: true,
    },
    element: Accommodation,
  },
  lodge_checkout: {
    fr: '/lodges/checkout/:slug/:variantId/',
    en: '/en/lodges/checkout/:slug/:variantId/',
    es: '/es/lodges/checkout/:slug/:variantId/',
    options: {
      shouldIndex: false,
    },
    element: AccommodationCheckout,
  },
  conversations: {
    fr: '/messages/',
    en: '/en/messages/',
    es: '/es/messages/',
    options: {
      shouldIndex: false,
      private: true,
    },
    element: Conversations,
  },
  conversation: {
    fr: '/message/:conversation_id/',
    en: '/en/message/:conversation_id/',
    es: '/es/message/:conversation_id/',
    options: {
      shouldIndex: false,
      private: true,
    },
    element: ConversationFlow,
  },
  conversation_by_host: {
    fr: '/message/:conversation_id/:host_token/',
    en: '/en/message/:conversation_id/:host_token/',
    es: '/es/message/:conversation_id/:host_token/',
    options: {
      shouldIndex: false,
    },
    element: ConversationFlow,
  },
  conversation_booking_by_travel: {
    fr: '/new-message/:booking_id/',
    en: '/en/new-message/:booking_id/',
    es: '/es/new-message/:booking_id/',
    options: {
      shouldIndex: false,
      private: true,
    },
    element: ConversationBookingTravelerNew,
  },
  conversation_booking_by_host: {
    fr: '/new-message/:booking_id/:host_token/',
    en: '/en/new-message/:booking_id/:host_token/',
    es: '/es/new-message/:booking_id/:host_token/',
    options: {
      shouldIndex: false,
    },
    element: ConversationBookingHostNew,
  },
  booking_accept: {
    fr: '/accept/:booking_id/:host_token/',
    en: '/en/accept/:booking_id/:host_token/',
    es: '/es/accept/:booking_id/:host_token/',
    options: {
      shouldIndex: false,
    },
    element: BookingAccept,
  },
  booking_deny: {
    fr: '/refuse/:booking_id/:host_token/',
    en: '/en/refuse/:booking_id/:host_token/',
    es: '/es/refuse/:booking_id/:host_token/',
    options: {
      shouldIndex: false,
    },
    element: BookingRefuse,
  },
  password_reset: {
    fr: '/forgot-password/:token/',
    en: '/en/forgot-password/:token/',
    es: '/es/forgot-password/:token/',
    options: {
      shouldIndex: false,
    },
    element: PasswordReset,
  },
  validate_email: {
    fr: '/validation-email/:token/',
    en: '/en/validation-email/:token/',
    es: '/es/validation-email/:token/',
    options: {
      shouldIndex: false,
    },
    element: ValidateEmail,
  },
  account_profile: {
    fr: '/u/my-profile/',
    en: '/en/u/my-profile/',
    es: '/es/u/my-profile/',
    options: {
      shouldIndex: false,
      private: true,
    },
    element: AccountProfile,
  },
  account_bookings: {
    fr: '/u/my-bookings/',
    en: '/en/u/my-bookings/',
    es: '/es/u/my-bookings/',
    options: {
      shouldIndex: false,
      private: true,
    },
    element: AccountBookings,
  },
  account_explorations: {
    fr: '/u/my-explorations/',
    en: '/en/u/my-explorations/',
    es: '/es/u/my-explorations/',
    options: {
      shouldIndex: false,
      private: true,
    },
    element: AccountExplorations,
  },
  account_exploration_map: {
    fr: '/u/exploration-map/:explorationId/',
    en: '/en/u/exploration-map/:explorationId/',
    es: '/es/u/exploration-map/:explorationId/',
    options: {
      shouldIndex: false,
      private: true,
    },
    element: AccountExplorationMap,
  },
  page: {
    fr: '/p/:pageSlug/',
    en: '/en/p/:pageSlug/',
    es: '/es/p/:pageSlug/',
    options: {
      shouldIndex: true,
    },
    element: Page,
  },
  page_id: {
    fr: '/p/id/:id/',
    en: '/en/p/id/:id/',
    es: '/es/p/id/:id/',
    options: {
      shouldIndex: false,
    },
    element: PageId,
  },
  experience_new_request: {
    fr: '/experience-proposal/',
    en: '/en/experience-proposal/',
    es: '/es/experience-proposal/',
    options: {
      shouldIndex: true,
      private: true,
    },
    element: NewExperience,
  },
  academies: {
    fr: '/academies/',
    en: '/en/academies/',
    es: '/es/academies/',
    options: {
      shouldIndex: true,
    },
    element: Academies,
  },
  academy2021: {
    fr: '/academies/academy-2021/',
    en: '/en/academies/academy-2021/',
    es: '/es/academies/academy-2021/',
    options: {
      shouldIndex: true,
    },
    element: Academy2021,
  },
  summit: {
    fr: '/academies/academy-2021/summit/',
    en: '/en/academies/academy-2021/summit/',
    es: '/es/academies/academy-2021/summit/',
    options: {
      shouldIndex: true,
    },
    element: Summit,
  },
  explorer_manifesto: {
    fr: '/academies/manifesto/',
    en: '/en/academies/manifesto/',
    es: '/es/academies/manifesto/',
    options: {
      shouldIndex: true,
    },
    element: ExplorerManifesto,
  },
  masterclass: {
    fr: '/academies/masterclass/:slug/',
    en: '/en/academies/masterclass/:slug/',
    es: '/es/academies/masterclass/:slug/',
    options: {
      shouldIndex: true,
    },
    element: MasterClass,
  },
  account_masterclass: {
    fr: '/u/masterclass/',
    en: '/en/u/masterclass/',
    es: '/es/u/masterclass/',
    options: {
      shouldIndex: true,
      private: true,
    },
    element: AccountMasterClass,
  },
  organisations: {
    fr: '/partners/',
    en: '/en/partners/',
    es: '/es/partners/',
    options: {
      shouldIndex: true,
    },
    element: Organisations,
  },
  organisation: {
    fr: '/partner/:slug/',
    en: '/en/partner/:slug/',
    es: '/es/partner/:slug/',
    options: {
      shouldIndex: true,
    },
    element: Organisation,
  },
  careers: {
    fr: '/careers/',
    en: '/en/careers/',
    es: '/en/careers/',
    options: {
      shouldIndex: true,
    },
    element: Careers,
  },
  payment_confirm: {
    fr: '/checkout/payment-confirm/:bookingId',
    en: '/en/checkout/payment-confirm/:bookingId',
    es: '/es/checkout/payment-confirm/:bookingId',
    options: {
      shouldIndex: false,
    },
    element: PaymentConfirm,
  },

  // HOST MENU
  host_accommodations: {
    fr: '/u/host/accommodations/',
    en: '/en/u/host/accommodations/',
    es: '/es/u/host/accommodations/',
    options: {
      shouldIndex: false,
      private: true,
    },
    element: HostAccommodations,
  },
  host_businesses: {
    fr: '/u/host/businesses/',
    en: '/en/u/host/businesses/',
    es: '/es/u/host/businesses/',
    options: {
      shouldIndex: false,
      private: true,
    },
    element: HostBusinesses,
  },
  host_bookings: {
    fr: '/u/host/bookings/',
    en: '/en/u/host/bookings/',
    es: '/es/u/host/bookings/',
    options: {
      shouldIndex: false,
      private: true,
    },
    element: HostBookings,
  },

  // CREATE
  explorer_accommodation_create: {
    fr: '/u/explorer/accommodation/create/',
    en: '/en/u/explorer/accommodation/create/',
    es: '/es/u/explorer/accommodation/create/',
    options: {
      shouldIndex: false,
      private: true,
    },
    element: ExplorerAccommodationCreate,
  },
  host_accommodation_create: {
    fr: '/u/host/accommodation/create/',
    en: '/en/u/host/accommodation/create/',
    es: '/es/u/host/accommodation/create/',
    options: {
      shouldIndex: false,
      private: true,
    },
    element: HostAccommodationCreate,
  },
  host_business_create: {
    fr: '/u/host/business/create/',
    en: '/en/u/host/business/create/',
    es: '/es/u/host/business/create/',
    options: {
      shouldIndex: false,
      private: true,
    },
    element: HostBusinessCreate,
  },
  host_variant_create: {
    fr: '/u/host/variant/create/:accommodationId/',
    en: '/en/u/host/variant/create/:accommodationId/',
    es: '/es/u/host/variant/create/:accommodationId/',
    options: {
      shouldIndex: false,
      private: true,
    },
    element: HostVariantCreate,
  },

  // EDIT
  explorer_accommodation_edit: {
    fr: '/u/explorer/accommodation/edit/:id/',
    en: '/en/u/explorer/accommodation/edit/:id/',
    es: '/es/u/explorer/accommodation/edit/:id/',
    options: {
      shouldIndex: false,
      private: true,
    },
    element: ExplorerAccommodationEdit,
  },
  host_accommodation_edit: {
    fr: '/u/host/accommodation/edit/:id/',
    en: '/en/u/host/accommodation/edit/:id/',
    es: '/es/u/host/accommodation/edit/:id/',
    options: {
      shouldIndex: false,
      private: true,
    },
    element: HostAccommodationEdit,
  },
  host_business_edit: {
    fr: '/u/host/business/edit/:id/',
    en: '/en/u/host/business/edit/:id/',
    es: '/es/u/host/business/edit/:id/',
    options: {
      shouldIndex: false,
      private: true,
    },
    element: HostBusinessEdit,
  },
  host_variant_edit: {
    fr: '/u/host/variant/edit/:accommodationId/:variantId/',
    en: '/en/u/host/variant/edit/:accommodationId/:variantId/',
    es: '/es/u/host/variant/edit/:accommodationId/:variantId/',
    options: {
      shouldIndex: false,
      private: true,
    },
    element: HostVariantEdit,
  },
  host_variant_convert: {
    fr: '/u/host/variant/convert/:accommodationId/:variantId/',
    en: '/en/u/host/variant/convert/:accommodationId/:variantId/',
    es: '/es/u/host/variant/convert/:accommodationId/:variantId/',
    options: {
      shouldIndex: false,
      private: true,
    },
    element: HostVariantConvert,
  },
  host_calendar: {
    fr: '/u/host/calendar/:id/',
    en: '/en/u/host/calendar/:id/',
    es: '/es/u/host/calendar/:id/',
    options: {
      shouldIndex: false,
      private: true,
    },
    element: HostCalendar,
  },

  // PREVIEW PAGES
  preview_blog_post: {
    fr: '/preview/blog_post/:slug/',
    en: '/en/preview/blog_post/:slug/',
    es: '/es/preview/blog_post/:slug/',
    options: {
      shouldIndex: false,
    },
    element: PreviewBlogArticle,
  },
  preview_experience: {
    fr: '/preview/experience/:slug/',
    en: '/en/preview/experience/:slug/',
    es: '/es/preview/experience/:slug/',
    options: {
      shouldIndex: false,
    },
    element: PreviewExperience,
  },
  preview_lodge: {
    fr: '/preview/lodge/:slug/',
    en: '/en/preview/lodge/:slug/',
    es: '/es/preview/lodge/:slug/',
    options: {
      shouldIndex: false,
    },
    element: PreviewAccommodation,
  },
  preview_masterclass: {
    fr: '/preview/masterclass/:slug/',
    en: '/en/preview/masterclass/:slug/',
    es: '/es/preview/masterclass/:slug/',
    options: {
      shouldIndex: false,
    },
    element: PreviewMasterclass,
  },
  preview_monty: {
    fr: '/preview/monty/:slug/',
    en: '/en/preview/monty/:slug/',
    es: '/es/preview/monty/:slug/',
    options: {
      shouldIndex: false,
    },
    element: PreviewMonty,
  },
  preview_organisation: {
    fr: '/preview/organisations/:slug/',
    en: '/en/preview/organisations/:slug/',
    es: '/es/preview/organisations/:slug/',
    options: {
      shouldIndex: false,
    },
    element: PreviewOrganisation,
  },
  preview_page: {
    fr: '/preview/p/:slug/',
    en: '/en/preview/p/:slug/',
    es: '/es/preview/p/:slug/',
    options: {
      shouldIndex: false,
    },
    element: PreviewPage,
  },
  search: {
    fr: '/search/',
    en: '/en/search/',
    es: '/es/search/',
    options: {
      shouldIndex: true,
    },
    element: Search,
  },
  host_chart: {
    fr: '/host/chart/',
    en: '/en/host/chart/',
    es: '/es/host/chart/',
    options: {
      shouldIndex: true,
    },
    element: HostChart,
  },
};

export default routes;
