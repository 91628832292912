import jsCookie from 'js-cookie';

import { getExpires } from './getExpires';

function setUserToken(token: string, rememberMe: boolean) {
  const options: Cookies.CookieAttributes = {
    expires: rememberMe ? getExpires() : undefined,
    sameSite: 'strict',
    secure: true,
  };

  jsCookie.set('userToken', token, options);
}

export { setUserToken };
