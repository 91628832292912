import TagManager from 'react-gtm-module';

const env = import.meta.env.VITE_ENV;

const isProd = env === 'production';

const gtmArgs = {
  gtmId: 'GTM-NWXPC8T',
  auth: isProd ? 'phUiFUnRJl1ydWCXe3OFDQ' : 'aUnIDwJe2Rb55vr9VnZSuQ',
  preview: isProd ? 'env-2' : 'env-10',
};

TagManager.initialize(gtmArgs);
