import { setUser } from '@sentry/react';

import TagManager from 'react-gtm-module';

import { apolloClient } from 'apollo/client';

function actionsOnLogout() {
  // GTM
  TagManager.dataLayer({
    dataLayer: {
      userId: undefined,
    },
  });

  // Sentry
  setUser(null);

  // Apollo Client
  apolloClient.clearStore();
}

export { actionsOnLogout };
