import { ReactNode } from 'react';

import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import { CacheProvider } from '@emotion/react';

import theme from 'material';

import createEmotionCache from 'createEmotionCache';

const cache = createEmotionCache();

interface MuiThemeProviderProps {
  children: ReactNode;
}

function MuiThemeProvider({ children }: MuiThemeProviderProps) {
  return (
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        {children}
      </ThemeProvider>
    </CacheProvider>
  );
}

export { MuiThemeProvider };
