const initialState = {
  visibleSnackbar: false,
  visibleSnackbarAuth: false,
  title: '',
  message: '',
  eventId: '',
};

type Action =
  | 'showSnackbar'
  | 'showSnackbarAuth'
  | 'hideSnackbar'
  | 'hideSnackbarAuth';

type Payload = {
  title: string;
  message: string;
  eventId: string; // comes from Sentry
};

export const store = {
  currentState: {
    visibleSnackbar: false,
    visibleSnackbarAuth: false,
    title: '',
    message: '',
    eventId: '',
  },
  listeners: new Set<Function>(),
  reducer(action: Action, payload?: Payload) {
    switch (action) {
      case 'showSnackbar':
        return {
          ...store.currentState,
          visibleSnackbar: true,

          title: payload!.title,
          message: payload!.message,
          eventId: payload!.eventId,
        };

      case 'showSnackbarAuth':
        return {
          ...store.currentState,
          visibleSnackbarAuth: true,
        };

      case 'hideSnackbar':
        return initialState;

      case 'hideSnackbarAuth':
        return initialState;

      default:
        return store.currentState;
    }
  },
  subscribe(callback: Function) {
    store.listeners.add(callback);

    return () => store.listeners.delete(callback);
  },
  getSnapshot() {
    return store.currentState;
  },
  dispatch(action: Action, payload?: Payload) {
    store.currentState = store.reducer(action, payload);
    store.listeners.forEach(l => l());

    return action;
  },
};
