import { ReactNode } from 'react';

import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';

import { useAuth } from 'contexts/Auth';

import { RoutesHelper } from 'helpers';

interface PrivateRouteProps {
  children: ReactNode;
}

function PrivateRoute({ children }: PrivateRouteProps) {
  const { i18n } = useTranslation();

  const { isAuthenticated } = useAuth();

  const location = useLocation();

  if (isAuthenticated) {
    return <>{children}</>;
  }

  const searchParams = new URLSearchParams({
    redirect_to: location.pathname,
  });

  return (
    <Navigate
      to={{
        pathname: RoutesHelper.routePath(i18n.language, 'login'),
        search: searchParams.toString(),
      }}
      replace
    />
  );
}

export { PrivateRoute };
