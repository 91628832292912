export const ignoreErrors = [
  //
  // https://docs.sentry.io/platforms/javascript/configuration/filtering/
  //

  // Random plugins/extensions
  'top.GLOBALS',

  // Facebook borked
  'fb_xd_fragment',

  //
  // https://blog.sentry.io/making-your-javascript-projects-less-noisy/
  //

  /**
   * Thrown when firefox prevents an add-on from referencing a DOM element that has been removed.
   * This can also be filtered by enabling the browser extension inbound filter
   */
  "TypeError: can't access dead object",
  /**
   * React internal error thrown when something outside react modifies the DOM
   * This is usually because of a browser extension or Chrome's built-in translate
   */
  "NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.",
  "NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.",

  //
  // The errors related to the Fetch API.
  //

  'Failed to fetch',
  'NetworkError when attempting to fetch resource.',
  'Load failed',

  //
  // The errors caused by every new deployment.
  //

  'Failed to fetch dynamically imported module',
  'Unable to preload CSS',
];
