import { Theme } from '@mui/material';

import { PickerComponents } from '@mui/x-date-pickers/themeAugmentation';

import { renderTimeViewClock } from '@mui/x-date-pickers';

const style: PickerComponents<Theme>['MuiDesktopTimePicker'] = {
  defaultProps: {
    viewRenderers: {
      hours: renderTimeViewClock,
      minutes: renderTimeViewClock,
      seconds: renderTimeViewClock,
    },
  },
};

export default style;
