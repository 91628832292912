import { ApolloClient, InMemoryCache, from, split } from '@apollo/client';

import { batchLink } from 'apollo/links/batchLink';
import { cancelRequestLink } from 'apollo/links/cancelRequestLink';
import { contextLink } from 'apollo/links/contextLink';
import { deprecationsLink } from 'apollo/links/deprecationsLink';
import { errorLink } from 'apollo/links/errorLink';
import { uploadLink } from 'apollo/links/uploadLink';
import { userTokenLink } from 'apollo/links/userTokenLink';

const links = [
  errorLink,
  contextLink,
  userTokenLink,
  deprecationsLink,
  cancelRequestLink,
  split(
    operation => operation.getContext().batch === true,
    batchLink,
    uploadLink
  ),
];

const apolloClient = new ApolloClient({
  link: from(links),
  cache: new InMemoryCache(),
});

export { apolloClient };
