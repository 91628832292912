import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { getSearchParameter } from 'helpers/getSearchParameter';
import { isCurrencyAccepted } from 'helpers/isCurrencyAccepted';

function getCurrencyFromUrl() {
  const currency = getSearchParameter('currency');

  return currency && isCurrencyAccepted(currency) ? currency : null;
}

function getCurrencyFromLocalStorage() {
  const currency = localStorage.getItem('currency');

  return currency && isCurrencyAccepted(currency) ? currency : null;
}

function getInitialCurrency() {
  const currencyFromUrl = getCurrencyFromUrl();

  if (currencyFromUrl) {
    return currencyFromUrl;
  }

  const currencyFromLocalStorage = getCurrencyFromLocalStorage();

  if (currencyFromLocalStorage) {
    return currencyFromLocalStorage;
  }

  return null;
}

function getLink() {
  const IP_API_KEY = import.meta.env.VITE_IP_API_KEY;

  const url = new URL('https://pro.ip-api.com/json/');

  url.searchParams.set('fields', 'currency');
  url.searchParams.set('key', IP_API_KEY);

  return url;
}

function getCurrencyFromIp() {
  return new Promise<string>(async (resolve, reject) => {
    let json;

    try {
      const link = getLink();
      const response = await fetch(link);

      json = await response.json();

      const currency = (json.currency as string).toLowerCase();

      currency && isCurrencyAccepted(currency)
        ? resolve(currency)
        : reject(null);
    } catch {
      reject(null);
    }
  });
}

interface ContextProps {
  currency: string;
}

const CurrencyContext = createContext<ContextProps>(null!);

interface ProviderProps {
  children: ReactNode;
}

function CurrencyProvider({ children }: ProviderProps) {
  const [currency, setCurrency] = useState<string | null>(getInitialCurrency);

  useEffect(() => {
    if (currency !== null && currency !== localStorage.getItem('currency')) {
      localStorage.setItem('currency', currency);
    }
  }, []);

  useEffect(() => {
    if (currency === null) {
      getCurrencyFromIp()
        .then(currencyFromIp => {
          setCurrency(currencyFromIp);
          localStorage.setItem('currency', currencyFromIp);
        })
        .catch(() => {
          setCurrency('usd');
          localStorage.setItem('currency', 'usd');
        });
    }
  }, []);

  const providerValue = {
    currency: currency ?? 'usd',
  };

  return (
    <CurrencyContext.Provider value={providerValue}>
      {children}
    </CurrencyContext.Provider>
  );
}

const useCurrency = () => {
  const context = useContext(CurrencyContext);

  if (context === undefined) {
    throw new Error('useCurrency must be used within a CurrencyProvider');
  }

  return context;
};

export { CurrencyContext, CurrencyProvider, useCurrency };
