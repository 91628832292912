type CustomFetch = WindowOrWorkerGlobalScope['fetch'];

export const customFetch: CustomFetch = (uri, options) => {
  const body = options?.body;

  if (typeof uri === 'string' && typeof body === 'string') {
    const bodyParsed = JSON.parse(body);

    if ('operationName' in bodyParsed) {
      // this makes debugging easier
      const newUri = uri.concat('?opname=', bodyParsed.operationName);

      return fetch(newUri, options);
    }
  }

  return fetch(uri, options);
};
