import { Components, Theme } from '@mui/material';

const style: Components<Theme>['MuiButton'] = {
  defaultProps: {
    disableElevation: true,
  },

  styleOverrides: {
    root: ({ ownerState }) => ({
      ...(ownerState.size === 'small' && {
        height: '32px',
      }),
      ...(ownerState.size === 'medium' && {
        height: '38px',
      }),
      ...(ownerState.size === 'large' && {
        height: '44px',
      }),
    }),
  },
};

export default style;
