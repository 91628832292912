import { useEffect } from 'react';

import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import * as Sentry from '@sentry/react';

import { isPrerender } from 'helpers/isPrerender';

import { allowUrls } from 'sentry/allowUrls';
import { denyUrls } from 'sentry/denyUrls';
import { ignoreErrors } from 'sentry/ignoreErrors';

const env = import.meta.env.VITE_ENV;
const dsn =
  import.meta.env.VITE_SENTRY_DSN ||
  'https://542ed77de5ef4efe94d7cbeecc32a36f@sentry.io/1769251';

Sentry.init({
  allowUrls: allowUrls,
  attachStacktrace: true,
  denyUrls: denyUrls,
  dsn: dsn,
  enabled: import.meta.env.VITE_ENV !== 'localhost' && !isPrerender(),
  environment: env,
  ignoreErrors: ignoreErrors,
  integrations: [
    Sentry.breadcrumbsIntegration({
      console: false,
    }),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracePropagationTargets: [
    // default
    'localhost',
    /^\//,

    /^http:\/\/api\.local\.vaolo\.com/,
    /^https:\/\/api\.local\.vaolo\.com/,
    /^https:\/\/api\.dev\.vaolo\.com/,
    /^https:\/\/api\.staging\.vaolo\.com/,
    /^https:\/\/api\.vaolo\.com/,
  ],
  tracesSampler: samplingContext => {
    const name = samplingContext.transactionContext.name;

    if (name.includes('checkout')) {
      return 0.8;
    }

    if (samplingContext.location) {
      return 0.2;
    }

    return 0;
  },
});
